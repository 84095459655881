@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);
.title {
    margin: 0;
    color: #ffffff !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.k-appbar-section ul {
    font-size: 14px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

li {
    margin: 0 10px;
}

li:hover {
    cursor: pointer;
    color: #84cef1;
}

.k-form {
    margin: 0 auto;
    padding-bottom: 10px;
}

.projectForm {
    max-width: 800px;
}

.taskList {
    display: block;
}

.formTitle {
    font-weight: bold;
    font-size: x-large;
    background-color: cornflowerblue;
    text-align: center;
}

.pageHeader {
    color: #004b87 !important;
    font-weight: bold;
    font-size: x-large;
    text-align: center;
    /* border-bottom-color: #ff8200 !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important; */
}

.topCenter {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 999999;
}

.addTasks {
    margin-left: 20px !important;
}

.taskListDialog .k-dialog {
    width: 500px;
    max-height: 90%;
}

.taskListDialog .k-dialog-content {
    padding-bottom: 20px;
}

.taskListDialog .k-window-titlebar {
    padding-top: 2px;
    padding-bottom: 2px;
}

.taskDelete {
    float: right !important;
}

.taskName {
    min-width: 250px;
    width: 250px;
    margin-right: 10px;
}

.taskClassification {
    min-width: 150px;
    width: 150px;
    margin-right: 10px;
}

.taskDueDate {
    min-width: 150px;
    width: 150px;
    margin-right: 10px;
}

.taskBillable {
    min-width: 50px;
    width: 50px;
    margin-right: 10px;
}

.taskResource {
    min-width: 200px;
    width: 200px;
    margin-right: 10px;
}

.taskUpload {
    min-width: 150px;
    width: 150px;
    margin-right: 10px;
}

.k-textarea {
    background-color: #ffffff !important;
}

.k-appbar {
    background-color: #004b87 !important;
    border-bottom-color: #ff8200 !important;
    border-bottom-width: 5px !important;
    line-height: 2 !important;
}

.loader-wrapper {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
}

.loader-wrapper div {
    margin: 0 auto;
    justify-content: center;
    align-content: center;
    display: flex;
    top: 50%;
}

.k-textarea>.k-input {
    padding: 0px !important;
}

.k-grid-edit-row .k-textbox {
    border-bottom-width: 1px !important;
}

.k-grid-edit-row>td>.k-textbox {
    background-color: rgba(0, 0, 0, 0) !important;
}

.k-grid-edit-row .k-datepicker .k-picker-wrap {
    border-bottom-width: 1px !important;
}

.k-grid table tr:hover {
    background-color: none !important;
}

.k-form-field {
    padding-left: 5px;
}

.taskwarning {
    font-style: italic;
}

.k-loader-canvas{
    position: static !important;
}

.pullLeft{
    width: 50%;
    float: left;
}

.pullRight{
    margin-left: 50%;
}

.label-blue{
    color: #004b87;
    font-weight: bold;
}


body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

